import React from 'react';
import Img from 'gatsby-image';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { Layout, SEO } from '../components';
//
import './services.scss';
//
import IconPhone from '../assets/svg/icon-phone.svg';

const Services = () => {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { relativeDirectory: { eq: "services" } }, sort: { fields: size }) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      file(relativePath: { eq: "motorcycle_repair_shop.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      sanityServicesMetadata {
        metadata {
          title
          keywords
          description
          image {
            asset {
              url
            }
          }
        }
      }
    }
  `);
  let bgParalax = { '--bg-image': `url("${data.file.childImageSharp.fluid.srcWebp}")` };

  const { metadata } = data.sanityServicesMetadata;
  return (
    <Layout>
      <SEO path="/services" {...metadata} />
      <div className="services-page">
        <section className="section-header container">
          <h1 className="section-title n-b-m n-t-m text-black">JP Superbikes Service Center - SW Florida</h1>
          <ul className="bread-crumbs">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li className="current-bread-crumb">Services</li>
          </ul>
        </section>
        <hr />
        <section className="services-offer container">
          <div className="general">
            <Img fluid={data.allFile.edges[13].node.childImageSharp.fluid} alt="Sulivans logo image" />
            <h2 className="text-black">General Motorcycle Services</h2>
            <hr className="hr-services" />
            <p className="pailed-text">
              Welcome to JP Superbikes LLC! We provide motorcycle, scooter and ATV/UTV performance upgrades,
              manitenance, tires, parts, and more. We offer full brake service, fluid replacement, brake lines upgrade.
              From tire replacement to engine rebuild our technicians are certified to diagnose and repair your vehicle
              so you can get back on the road.
            </p>
          </div>
          <div className="repair">
            <h2 className="text-black">JP Superbikes Repair Services</h2>
            <hr className="hr-services" />
            <h4 className="text-black">Regular Motorcycle Service</h4>
            <p className="n-b-m">
              <span className="anime-icon moto-icon" />
              <span className="pailed-text">
                Short service consists of a
                <strong>change of oiland filter plus chain adjustment &amp; lubrication</strong> as well as a thorough
                inspection of virtually all the items covered in a full service - almost everything we can check without
                actually dismantling your vehicle.
              </span>
            </p>
            <hr />
            <h4 className="text-black">Full Motorcycle Service</h4>
            <p>
              <span className="anime-icon repair-icon" />
              <span className="pailed-text">
                The full service consists of an engine and carburetor maintenance including valve clearance, new oil and
                air filter, nw spark plugs, coolant replacement, fuel injection system or carburetor cleaning and
                synchronization.
              </span>
            </p>
            {/* <div className="btn-wrap">
              <Link to="/contact" className="btn btn-black">
                SUPERSTORE CONTACT
              </Link>
              <Link to="/contact" className="btn btn-black">
                SERVICE CENTER CONTACT
              </Link>
            </div> */}
          </div>
          <div className="left-img">
            <Img fluid={data.allFile.edges[14].node.childImageSharp.fluid} alt="Sulivans logo image" />
          </div>
          <div className="right-img">
            <Img fluid={data.allFile.edges[12].node.childImageSharp.fluid} alt="Sulivans logo image" />
          </div>
        </section>
        <section style={bgParalax} className="services-paralax">
          <div className="container">
            <div className="services-cta">
              <h2>JP Superbikes is around the corner for you!</h2>
              <p>
                We will make sure that you are satisfied with our
                <strong>motorcycles and service.</strong>
              </p>
              <Link className="btn-hollow link-contact-cta" to="/contact">
                CONTACT US
              </Link>
            </div>
          </div>
        </section>
        <section className="call-us">
          <div className="container">
            <h2 className="smaller">Need Motorcycle Service? Please Call Us!</h2>
            <h2 className="smaller with-icon">
              <span>
                <IconPhone />
              </span>
              (941) 883-6363
            </h2>
          </div>
        </section>
        <section className="supported-makes text-center container">
          <h3 className="text-black">Major Supported Makes</h3>
          <hr className="hr-services" />
          <div className="makers-grid">
            <Img
              fluid={{ ...data.allFile.edges[4].node.childImageSharp.fluid, aspectRatio: 1 }}
              imgStyle={{ objectFit: 'contain' }}
              alt="maker logo image"
              className="big-padding"
            />
            <Img
              fluid={{ ...data.allFile.edges[2].node.childImageSharp.fluid, aspectRatio: 1 }}
              imgStyle={{ objectFit: 'contain' }}
              className="big-padding"
              alt="maker logo image"
            />
            <Img
              fluid={{ ...data.allFile.edges[5].node.childImageSharp.fluid, aspectRatio: 1 }}
              imgStyle={{ objectFit: 'contain' }}
              alt="maker logo image"
            />
            <Img
              fluid={{ ...data.allFile.edges[0].node.childImageSharp.fluid, aspectRatio: 1 }}
              imgStyle={{ objectFit: 'contain' }}
              alt="maker logo image"
            />
            <Img
              fluid={{ ...data.allFile.edges[3].node.childImageSharp.fluid, aspectRatio: 1 }}
              imgStyle={{ objectFit: 'contain' }}
              alt="maker logo image"
            />
            <Img
              fluid={{ ...data.allFile.edges[6].node.childImageSharp.fluid, aspectRatio: 1 }}
              imgStyle={{ objectFit: 'contain' }}
              alt="maker logo image"
              className="big-padding"
            />
            <Img
              fluid={{ ...data.allFile.edges[9].node.childImageSharp.fluid, aspectRatio: 1 }}
              imgStyle={{ objectFit: 'contain' }}
              alt="maker logo image"
              className="big-padding"
            />
            <Img
              className="big-padding"
              fluid={{ ...data.allFile.edges[10].node.childImageSharp.fluid, aspectRatio: 1 }}
              imgStyle={{ objectFit: 'contain' }}
              alt="maker logo image"
            />
            <Img
              fluid={{ ...data.allFile.edges[11].node.childImageSharp.fluid, aspectRatio: 1 }}
              imgStyle={{ objectFit: 'contain' }}
              alt="maker logo image"
              className="big-padding"
            />
            <Img
              fluid={{ ...data.allFile.edges[7].node.childImageSharp.fluid, aspectRatio: 1 }}
              imgStyle={{ objectFit: 'contain' }}
              alt="maker logo image"
              className="big-padding"
            />
            <Img
              fluid={{ ...data.allFile.edges[2].node.childImageSharp.fluid, aspectRatio: 1 }}
              imgStyle={{ objectFit: 'contain' }}
              alt="maker logo image"
              className="big-padding"
            />
            <Img
              className="big-padding"
              fluid={{ ...data.allFile.edges[1].node.childImageSharp.fluid, aspectRatio: 1 }}
              imgStyle={{ objectFit: 'contain' }}
              alt="maker logo image"
            />
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default Services;
